

.changePasswordAnim-enter {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  .changePasswordAnim-enter-active {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
    transition: opacity 400ms, transform 400ms;
  }
  .changePasswordAnim-exit {
    opacity: 1;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  .changePasswordAnim-exit-active {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
    transition: opacity 400ms, transform 400ms;
  }