.lst-kix_qfddpx5nhsky-7>li:before {
  content: "\0025cb  "
}

.lst-kix_qfddpx5nhsky-5>li:before {
  content: "\0025a0  "
}

.lst-kix_470v1v51oxkg-8>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-8
}

.lst-kix_qfddpx5nhsky-6>li:before {
  content: "\0025cf  "
}

.lst-kix_qfddpx5nhsky-0>li:before {
  content: "\0025cf  "
}

.lst-kix_qfddpx5nhsky-8>li:before {
  content: "\0025a0  "
}

.lst-kix_470v1v51oxkg-7>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-7
}

.lst-kix_apdeuzlk95fz-4>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-4
}

ol.lst-kix_470v1v51oxkg-6.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-6 0
}

ol.lst-kix_apdeuzlk95fz-1.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-1 0
}

.lst-kix_88uo4hol67t7-8>li:before {
  content: "\0025a0  "
}

.lst-kix_88uo4hol67t7-7>li:before {
  content: "\0025cb  "
}

.lst-kix_apdeuzlk95fz-5>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-5
}

.lst-kix_apdeuzlk95fz-2>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-2
}

.lst-kix_qfddpx5nhsky-1>li:before {
  content: "\0025cb  "
}

.lst-kix_qfddpx5nhsky-2>li:before {
  content: "\0025a0  "
}

.lst-kix_qfddpx5nhsky-3>li:before {
  content: "\0025cf  "
}

.lst-kix_470v1v51oxkg-6>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-6
}

.lst-kix_470v1v51oxkg-0>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-0, decimal) ". "
}

.lst-kix_qfddpx5nhsky-4>li:before {
  content: "\0025cb  "
}

ol.lst-kix_apdeuzlk95fz-3.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-3 0
}

.lst-kix_apdeuzlk95fz-6>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-6
}

.lst-kix_apdeuzlk95fz-0>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-0
}

ol.lst-kix_470v1v51oxkg-4.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-4 0
}

.lst-kix_470v1v51oxkg-4>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-4
}

ol.lst-kix_470v1v51oxkg-3.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-3 0
}

ul.lst-kix_88uo4hol67t7-1 {
  list-style-type: none
}

ul.lst-kix_88uo4hol67t7-0 {
  list-style-type: none
}

ol.lst-kix_apdeuzlk95fz-4.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-4 0
}

ul.lst-kix_88uo4hol67t7-7 {
  list-style-type: none
}

ul.lst-kix_88uo4hol67t7-6 {
  list-style-type: none
}

ul.lst-kix_88uo4hol67t7-8 {
  list-style-type: none
}

ul.lst-kix_88uo4hol67t7-3 {
  list-style-type: none
}

ul.lst-kix_88uo4hol67t7-2 {
  list-style-type: none
}

ul.lst-kix_88uo4hol67t7-5 {
  list-style-type: none
}

ul.lst-kix_88uo4hol67t7-4 {
  list-style-type: none
}

.lst-kix_apdeuzlk95fz-3>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-3
}

ol.lst-kix_470v1v51oxkg-2.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-2 0
}

ol.lst-kix_apdeuzlk95fz-3 {
  list-style-type: none
}

ul.lst-kix_qfddpx5nhsky-7 {
  list-style-type: none
}

ol.lst-kix_apdeuzlk95fz-4 {
  list-style-type: none
}

ul.lst-kix_qfddpx5nhsky-6 {
  list-style-type: none
}

.lst-kix_470v1v51oxkg-2>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-2
}

ol.lst-kix_apdeuzlk95fz-5 {
  list-style-type: none
}

ol.lst-kix_apdeuzlk95fz-6 {
  list-style-type: none
}

ul.lst-kix_qfddpx5nhsky-8 {
  list-style-type: none
}

ol.lst-kix_apdeuzlk95fz-7 {
  list-style-type: none
}

ul.lst-kix_qfddpx5nhsky-3 {
  list-style-type: none
}

ol.lst-kix_apdeuzlk95fz-8 {
  list-style-type: none
}

ul.lst-kix_qfddpx5nhsky-2 {
  list-style-type: none
}

ul.lst-kix_qfddpx5nhsky-5 {
  list-style-type: none
}

ul.lst-kix_qfddpx5nhsky-4 {
  list-style-type: none
}

.lst-kix_apdeuzlk95fz-1>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-1, lower-latin) ". "
}

ol.lst-kix_apdeuzlk95fz-8.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-8 0
}

.lst-kix_apdeuzlk95fz-0>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-0, decimal) ". "
}

ul.lst-kix_qfddpx5nhsky-1 {
  list-style-type: none
}

.lst-kix_apdeuzlk95fz-8>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-8
}

ul.lst-kix_qfddpx5nhsky-0 {
  list-style-type: none
}

.lst-kix_470v1v51oxkg-1>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-1
}

ol.lst-kix_470v1v51oxkg-3 {
  list-style-type: none
}

ol.lst-kix_470v1v51oxkg-2 {
  list-style-type: none
}

ol.lst-kix_470v1v51oxkg-1 {
  list-style-type: none
}

ol.lst-kix_470v1v51oxkg-0 {
  list-style-type: none
}

ol.lst-kix_470v1v51oxkg-7 {
  list-style-type: none
}

ol.lst-kix_470v1v51oxkg-6 {
  list-style-type: none
}

ol.lst-kix_470v1v51oxkg-5 {
  list-style-type: none
}

ol.lst-kix_470v1v51oxkg-4 {
  list-style-type: none
}

ol.lst-kix_470v1v51oxkg-8.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-8 0
}

.lst-kix_470v1v51oxkg-0>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-0
}

.lst-kix_apdeuzlk95fz-7>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-7
}

ol.lst-kix_470v1v51oxkg-5.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-5 0
}

ol.lst-kix_apdeuzlk95fz-5.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-5 0
}

.lst-kix_470v1v51oxkg-3>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-3
}

ol.lst-kix_470v1v51oxkg-8 {
  list-style-type: none
}

ol.lst-kix_apdeuzlk95fz-0 {
  list-style-type: none
}

ol.lst-kix_apdeuzlk95fz-2.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-2 0
}

ol.lst-kix_apdeuzlk95fz-1 {
  list-style-type: none
}

ol.lst-kix_apdeuzlk95fz-2 {
  list-style-type: none
}

.lst-kix_470v1v51oxkg-1>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-1, lower-latin) ". "
}

.lst-kix_470v1v51oxkg-2>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-2, lower-roman) ". "
}

.lst-kix_470v1v51oxkg-4>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-4, lower-latin) ". "
}

.lst-kix_470v1v51oxkg-5>li {
  counter-increment: lst-ctn-kix_470v1v51oxkg-5
}

.lst-kix_88uo4hol67t7-1>li:before {
  content: "\0025cb  "
}

ol.lst-kix_470v1v51oxkg-0.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-0 0
}

.lst-kix_470v1v51oxkg-3>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-3, decimal) ". "
}

.lst-kix_470v1v51oxkg-6>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-6, decimal) ". "
}

ol.lst-kix_apdeuzlk95fz-6.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-6 0
}

.lst-kix_apdeuzlk95fz-1>li {
  counter-increment: lst-ctn-kix_apdeuzlk95fz-1
}

.lst-kix_88uo4hol67t7-2>li:before {
  content: "\0025a0  "
}

.lst-kix_470v1v51oxkg-5>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-5, lower-roman) ". "
}

.lst-kix_88uo4hol67t7-3>li:before {
  content: "\0025cf  "
}

.lst-kix_88uo4hol67t7-4>li:before {
  content: "\0025cb  "
}

.lst-kix_88uo4hol67t7-6>li:before {
  content: "\0025cf  "
}

.lst-kix_470v1v51oxkg-8>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-8, lower-roman) ". "
}

.lst-kix_470v1v51oxkg-7>li:before {
  content: ""counter(lst-ctn-kix_470v1v51oxkg-7, lower-latin) ". "
}

.lst-kix_88uo4hol67t7-5>li:before {
  content: "\0025a0  "
}

ol.lst-kix_470v1v51oxkg-1.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-1 0
}

.lst-kix_apdeuzlk95fz-7>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-7, lower-latin) ". "
}

.lst-kix_apdeuzlk95fz-8>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-8, lower-roman) ". "
}

ol.lst-kix_apdeuzlk95fz-0.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-0 0
}

.lst-kix_apdeuzlk95fz-3>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-3, decimal) ". "
}

.lst-kix_apdeuzlk95fz-5>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-5, lower-roman) ". "
}

.lst-kix_88uo4hol67t7-0>li:before {
  content: "\0025cf  "
}

ol.lst-kix_apdeuzlk95fz-7.start {
  counter-reset: lst-ctn-kix_apdeuzlk95fz-7 0
}

.lst-kix_apdeuzlk95fz-2>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-2, lower-roman) ". "
}

.lst-kix_apdeuzlk95fz-6>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-6, decimal) ". "
}

ol.lst-kix_470v1v51oxkg-7.start {
  counter-reset: lst-ctn-kix_470v1v51oxkg-7 0
}

.lst-kix_apdeuzlk95fz-4>li:before {
  content: ""counter(lst-ctn-kix_apdeuzlk95fz-4, lower-latin) ". "
}

ol {
  margin: 0;
  padding: 0
}

table td,
table th {
  padding: 0
}

.c6 {
  border-right-style: solid;
  border-top-width: 0pt;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-bottom-style: solid;
  padding-right: 0pt
}

.c10 {
  color: var(--primary);
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-style: normal
}

.c5 {
  color: var(--primary);
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 18px;
  font-style: normal
}

.c0 {
  color: var(--primary);
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16px;
 line-height: 1.4;
  font-style: normal
}

.c11 {
  color: var(--primary);
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
 
  font-style: normal
}

.c15 {
  padding-top: 0pt;
  padding-bottom: 15pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c3 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c9 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: left
}

.c8 {
  color: var(--primary);
  text-decoration: none;
  vertical-align: baseline;
  
}

.c14 {
  font-size: 11pt;
  font-weight: 700;
  color: var(--primary);
}

.c2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

.c16 {}

.c12 {
  border: 1px solid black;
  margin: 5px
}

.c1 {
  background-color: #ffffff
}

.c13 {
  margin-left: 36pt
}

.c4 {
  height: 11pt
}

p {
  margin: 0;
  color: var(--primary);
  font-size: 11pt;
}
