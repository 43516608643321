.profile-picture,
.profile-picture-default {
  position: relative;
  width: 200px;
  height: 150px;
  border-radius: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--background);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding: 5px;
  background-origin: content-box;
  box-sizing: border-box;

  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  &:after {
    // content: 'Change Profile Picture';
    position: absolute;
    color: var(--white);
    // background-color: rgba(0,0,0,0.5);
    border-radius: 8px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease;
    padding: 70px 5px;
    font-size: 13px;
    text-align: center;
    font-family: "Roboto", sans-serif;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    .changePicture {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      z-index: 999999999;
      width: 100%;
      height: 100%;
      text-align: center;
      backdrop-filter: blur(10px);
    }
    &:after {
      opacity: 1;
    }
  }

  .changePicture {
    display: none;
  }
}

.profile-picture-default {
  background-image: url("../../../Images/user.png");
  background-size: contain;
  background-origin: content-box;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
  box-sizing: border-box;
  padding: 30px;
}
