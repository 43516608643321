.container {
    position: relative;
    width: 50%;
    max-width: 300px;
  }

  
  .overlay {
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }
  
  .container:hover .overlay {
    opacity: 1;
  }

  /* relevant styles */
.img__wrap {
  position: relative;
  overflow:visible;
  border-radius: 50%;
}

.img__wrap:hover .middle {
  display: visible;
  opacity: 1;
  

}

.text {
  
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 70%;
  left: 0%;
  width: 50px;
  /* transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); */
  cursor: pointer;
}