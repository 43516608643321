:root {
  --white: #ffffff;
  --black: #000000;
  --transparent: rgba(0, 0, 0, 0);
  --background: #f5f5f5;
  --backgroundHover: #f0f0f0;
  --accent: #98235c;
  --primary: #314155;
  --primary07: rgba(49, 56, 85, 0.7);
  --primaryHover: #263343;
  --secondary: #ec4252;
  --secondary015: rgba(236, 66, 82, 0.15);
  --disabled: #cccccc;
  --border: #cecece;
}

body {
  display: flex;
  margin: 0;
  /* width: 100vw; */
  height: 100vh;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  background-color: var(--background) !important;
  padding-right: 0 !important;
}

* {
  /* transition: .1s !important; */
}

.disableSelection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.enableChat {
  display: block;
}

.disableChat {
  display: none;
}
