.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-height: 100% !important;
  overflow: auto;
}

html, 
body {
    height: 100%;
    overflow: auto;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.appBar {
  background-color: var(--accent);
  padding: 15;
  display: "flex";
  flex-direction: "row";
  justify-content: "space-between";
  align-items: "center";
}
.content {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.loadingContainer,
.loading {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginError {
  color:#EC4252;
  text-align: right;
  font-size: 15px;
}
.ConfirmPasswordError {
  color: #EC4252;
  text-align: left;
  font-size: 15px;
  max-width: 200px;
}
.resetPasswordInfo{

  text-align: left;
 
  max-width: 80%;
}

@media (min-width:2000px) {
  .resetPasswordInfo{
    color:lightslategray;
    text-align: left;
    font-size: 15px;
    width: 70%;
  }
}
.settingsDrawer {
  margin-top: 40px;
}

.supportTitle {
  font-size: 24px;
  color: #98235c;
  font-weight: 600;
}
.boldLabel {
  color: var(--primary);
  font-weight: 600;
}
.linkAddress {
  color: #314155;
  font-weight: 400;
  text-decoration: underline;
  font-size: 18px;
}

.DeleteAccountContent {
  display: flex;
  flex-grow: 1;
  max-width: 700px;
  flex-direction: column;
  /* max-height: 500px; */
  /* padding: 50px 30px 50px 30px; */
}
.passSubcontainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 40px;
  margin: 0 auto;
  width: 90%;
}
.DeleteAccountSubcontainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding:  0;
  justify-content: space-between;
  flex-shrink: 0;
}
.DeleteAccountDescription {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.DeletePlanSubcontainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding:  30px;
  justify-content: space-between;
  flex-shrink: 0;
}
.passwordSection {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 800px;
}


.submitButton {
  width: 100%;
  align-self: flex-start;
  font-size: 14;
}

.changePlanButton {
  align-self: flex-end;
}

.passwordLabel {
  color: hsl(0, 0%, 50%) !important;
  padding-right: 20px !important;
}

.passwordLabelDisabled {
  color: var(--primary) !important;
  padding-right: 20px !important;
  opacity: 0.5;
}

.menu {
  background-color: #98235c;
  display: flex;
  align-items: center;
}
.mainDrawer {
  background-color: rgb(2, 197, 165);
}
.profile {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.profileDetails {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  flex-direction:column;
  width: 50%;
}
.firstContainer {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-direction: column;
}
.profileSection {
  display: flex;
  flex: 1;
  min-width: 200px;
  flex-direction: column;
  margin: 0 15px;
}
.profileSectionPhoto {
  display: flex;
  flex-direction: column;
  /* margin: 0 15px; */
  flex: 1;
}
.sectionTitle {
  color: var(--primary);
  font-size: 20px;
}

.profileTitle {
  /* padding-left: 15px; */
  color: var(--primary);
  font-size: 18px;
}

.planContainer {
  margin: 0px 15px 30px;
  display: flex;
  flex-direction: column;
}

.saveButton {
  width: 200px;
  align-self: flex-end;
}

.imageContainer {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  align-self: flex-start;
}
.imageUploader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: var(--background);
  border-radius: 65px;
  border: 2px solid var(--primary);
}
.profileIcon {
  width: 60px;
  height: 60px;
}
.profileUsername {
  color: var(--primary);
}
.accountType {
  flex-shrink: 1;
  padding: 8px 15px;
  border-radius: 10px;
  color: var(--secondary);
  background-color: var(--secondary015);
  font-size: 12px;
}
.nameSurnameLabel {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
  color: var(--primary);
  font-weight: 500;
}
.deleteAccountLabel {
  color: var(--primary);
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.4;
}

.logoutDrawer {
  font-size: 18px;
  margin-left: 10px;
}
.supportContentDescription {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  flex: 1;
}

.supportContentDescription span {
  margin-bottom: 25px;
  font-size: 18px;
}
.supportSubContent {
  flex-direction: row;
  display: flex;
}
.supportImgContainer {
  display: flex;
  flex: 1;
}

.suggestedPlan {
  background-color: #98235c;
  padding: 30px;
  text-align: center;
  color: white;
  max-width: 500px;
  border-radius: 10px;
}
.linkAddressSuggestPlan {
  color: white;
  font-weight: 300;
  text-decoration: none;
}
.copyIcon {
  width: 20px;
  height: 20px;
  padding: 0 25px;
}

.notFoundText {
  margin: 30px 0;
}
.linkButton {
  min-width: 25ch;
  padding: 22.5px 30px;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  border-radius: 10px;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  padding: 15px 15px 0;
}
.logoIcon {
  min-width: 45px;
  max-width: 55px;
  height: 125px;
  object-fit: contain;
  cursor: pointer;
}
.logoName {
  margin-left: 10px;
  max-width: 153px;
  object-fit: contain;
  cursor: pointer;

}
.drawerContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding-bottom: 7px;
  margin-top: 60px;
}

.listItemIcon {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemInactive {
  opacity: 0.5;
}
.listIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 18px;
}
.listItemTitle {
  color: var(--accent);
}
.listItemTitleActive {
  font-weight: 900;
}

.listItemNestedTitle {
  color: var(--accent);
}
.listItemNestedTitleActive {
  font-weight: 900;
  color: var(--accent);
}

.toggleDrawerButton {
  width: 40px;
  padding: 0 !important;
  position: absolute !important;
  background-color: var(--white) !important;
  bottom: 50px;
  right: -25px;
  z-index: 1300 !important;
}

.toggleDrawerIcon {
  width: 20px;
  height: 20px;
}

.usernameDashboard {
  text-align: end;
  margin-top: 10px;
  font-size: 18px;
}

.logoDashboard {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.headerRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.timeDashboard {
  margin-top: 0;
}
.dashboardTitle {
  margin-bottom: 10px;
}

.contentContainerWrapper {
  /* max-width: 1100px; */
  width: 100%;
  position: relative;
  margin: 0 auto 30px;
}

.contentContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  background-color: var(--white);
  /* border: 1px solid var(--border); */
  border-radius: 5px;
  padding: 24px 40px;
  /* max-width: 1000px; */
  /* margin: 0 auto 30px;
   */
   margin: 0px  24px 30px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.contentContainerFile {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  /* align-items: center; */
  /* justify-content: center; */
  background-color: var(--white);
  /* border: 1px solid var(--border); */
  border-radius: 5px;
  padding: 24px 40px;
  /* max-width: 1000px; */
  /* margin: 0 auto 30px;
   */
   margin: 0px  24px 30px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.contentContainerTarget {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  background-color: var(--white);
  /* border: 1px solid var(--border); */
  border-radius: 5px;
  
  /* max-width: 1000px; */
  /* margin: 0 auto 30px;
   */
   margin: 0px  0px 30px 0px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.contentContainerDashboard {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: start;
  justify-content: flex-start;
  background-color: var(--white);
  /* border: 1px solid var(--border); */
  border-radius: 15px;
  padding: 24px 40px;
  /* max-width: 1000px; */
  /* margin: 0 auto 30px;
   */
   margin: 0 24px 30px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.profileHeader {
  box-sizing: border-box;
  position: relative;
  height: 55px;
  padding: 34px 20px 0;
}

.backgroundHeader {
  background-color: var(--primary);
  height: 264px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.subHeader{
  display: flex;
flex: 1;
align-items: center;
background-color: #F4F4F4;
padding: 0px 24px;
margin-top: 5px;
}

.subHeader2 {
  display: flex;
align-items: center;
background-color: #F4F4F4;
padding: 0px 24px;
margin-top: 5px;

}
.accountContentContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-shrink: 0;
  width: 100%;
}
.tooltipsContentContainer {
  width: 100%;
  /* padding: 0 20px; */
  box-sizing: border-box;
}

.deleteAccountContainer {
  width: 100%;
  box-sizing: border-box;
}

.companyLogo {
  background-color: var(--background);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 10px;
  margin-right: 35px;
}

.cardContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  border-radius: 15px;
  padding: 50px;
  margin: 10px;
}

.mobileDrawer {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 80px;
  align-items: center;
  min-height: 70px;
  background-color: var(--white);
}

.mobileLogo {
  min-width: 60px;
  max-width: 170px;
  height: 125px;
  object-fit: contain;
  cursor: pointer;
}

.homeContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.cardIcon {
  margin: auto;
}

.smallCard {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 270px;
  text-decoration: none;
  border-radius: 5px;
}

.mailIcon {
  width: 20px;
  height: 20px;
}

.mailTo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 10px 10px 0;
}

.mailIconContainer {
  margin: 16px;
}

.sales {
  text-decoration: none;
  color: var(--primary);
  font-weight: 400;
  font-size: 18px;
}

.drawerWrapper {
  display: none;
}

.divider {
  margin-bottom: 15px !important;
}
.divider2 {
  margin-bottom: 15px !important;
  margin-inline: 25px !important;
}
.dividerFullWidth {
  width: 100% !important;
}
.targetsContainer {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 70px;
  padding-left: 15px;
  padding-right: 20px;
}

.preformated {
  white-space: pre-line;
  color: var(--primary);
}
.bullet {
  display: inline-block;
  margin: 0 5px 0 0;
  line-height: 25px;
  vertical-align: middle;
}
.lineItem {
  vertical-align: middle;
}
.mail {
  color: var(--secondary);
  text-decoration: none;
}
.definition {
  color: var(--secondary);
}

.arrowUp {
  width: 25px;
  height: 25px;
}

.scrollToTop {
  padding: 0 !important;
}

.changePasswordLogout {
  text-decoration: underline;
  color: var(--accent);
}
.accentTitle {
  margin-top: 25px;
}

.deleteAccountParagraph {
  margin-top: 10px;
  max-width: 70%;
  line-height: 1.4;
}

@media (min-width:2100px) {
  .deleteAccountParagraph {
    margin-top: 10px;
    max-width: 50%;
  }
}

@media (max-width:1350px) {
  .deleteAccountParagraph {
    margin-top: 10px;
    max-width: 100%;
  }
}

.inputttt {
  max-width: 640px;
  margin-bottom: 5px;
}

.modalDescription {
  font-size: 18px;
  color: var(--primary);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.reactCodeInput{
  display: flex !important;
  justify-content: center;
}
.reactCodeInput>input:focus-visible{
  outline: none;
  border:1px solid #811C4E !important ;
}

@keyframes slideCard {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

#cardUnderReason {
  animation-name: slideCard;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

@media (max-width:960px) {
  .profileDetails {
    width: 70%;
  }
  
}

@media (max-width:760px) {
  .contentContainer{
    padding: 16px;
    margin-inline: 10px;
  }
  .contentContainerFile {
    padding: 16px;
    margin-inline: 10px;
  }
 .contentContainerTarget{
    padding: 16px;
    margin-inline: 10px;
  }
  .profileDetails {
    width: 100%;
  }
  .imageContainer {
    /* align-items: flex-start; */
    flex-direction: column;
    align-self: center;
  }
  .sectionTitle{
    font-size: 22px;
  }
  .drawerContent {
    margin-top: 0px;
  }
  .subHeader2 {
  padding: 0px 10px;
  
  }
  
}

@media (max-width:600px) {
  .tooltipsContentContainer {
    padding: 0;
  }
  .subHeader{
    padding: 0 15px;
  }
}

@media (max-width:760px) {
  .subHeader{
    padding: 0 10px;
    z-index: 2;
  }
}

/* test animation */
.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
opacity: 1;
transition: opacity 1000ms linear;
}

/* enter */
.fade-enter {
opacity: 0;
z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms linear 500ms;
}

/* exit */
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}
.fade-exit-done {
opacity: 0;
}


.changePasswordAnim-enter {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  opacity: 1;
}
.changePasswordAnim-enter-active {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.changePasswordAnim-exit {
  opacity: 1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.changePasswordAnim-exit-active {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

/* Remove Arrow on input  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@keyframes slideLeft {
  from {
    transform: translateX(-300px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

#referalCard {
  animation-name: slideLeft;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}



@keyframes modalFadeIn {
  from {transform: translateX(-50%);opacity: 0;}
  to {transform: translateX(0);opacity: 1;}
}

#table {
  animation-name: modalFadeIn;
  animation-duration: 1s;
}
@keyframes showMore {
  from {transform: translateY(-50%);opacity: 0.5;}
  to {transform: translateX(0);opacity: 1;}
}

#showMore {
  animation-name: showMore;
  animation-duration: 0.5s;
}

.quicksight-embedding-iframe-target {
  min-height: 900px;
}

@media (max-width:760px) {
  .quicksight-embedding-iframe-target {
    min-height: 800px;
  }
}

.PhoneInputInput {
  min-height:52px !important;
  background-color: #F5F5F5;
  outline: none;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  font-size: 16px;
  color: hsl(0, 0%, 50%);
}

.PhoneInputCountry {
  /* border-right: 1px solid #314155 !important;
  padding-right: 7px !important;
  line-height: 4px !important; */
  position: relative;
  padding-right: 12px !important;
}

.PhoneInputCountry:after {
  content: " ";
  position: absolute;
  border-left: 1px #314155 solid;
  top: 25%;
  right: 0;
  left:20;
  height: 50%;
  margin-top: auto;
  margin-bottom: auto;
}


.PhoneInput {
  width: 100%;
  min-height:50px !important;
  background-color: #F5F5F5;
  outline: none;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
  font-size: 14px;
}

.PhoneInputCountrySelect {
  padding-right: 0px;
  border-right: 1px solid #314155;
}

.PhoneInputCountryIcon {
  height:auto !important;
  width: 30px !important;
  margin-left:12px !important;
}

.PhoneInputCountryIcon PhoneInputCountryIcon--border {
  border: none !important;
  outline: none !important;
}

.PhoneInput--focus {
  border: 1px solid #314155 !important;
}

/* .PhoneInputInput:focus {
  border: 1px solid #314155;
} */

.PhoneInputInput:required {
  border: 1px solid #EC4252;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#chatSection::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#chatSection {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.quicksight-embedding-iframe-target {
  min-height: 900px;
}

.quicksight-embedding-iframe-qsearch {
min-height: 60px !important;
}