.react-datepicker{
  padding: 5px 15px !important;
}


.firstInput{
    position: relative;
    width: 100%;
}
/* .firstInput::after {
  top: 20%;
  right: 0;
  width: 1px;
  height: 60%;
  content: "";
  position: absolute;
  background-color:#CECECE;
  
} */
.react-datepicker-wrapper {
  width: 100% !important;
}

.firstInputContainerDatePickerAnnual{
  color: grey !important;
  border: 1px solid#314155;
  padding: 15px 15px 15px 50px;
  position: relative;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */
  font-size: 16px;
  
}
.calendarIcon{
  position: absolute;
  left: 14px;
  top:13px
}

.react-datepicker {
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 35%);
  border: none !important;
}
.react-datepicker__header{
  background-color: white !important;
}

.react-datepicker__day--in-range{
  background-color: #F5F5F5 !important;
  color: black !important;
}
.react-datepicker__day--selected {
  background-color: #ec4253 !important;
  color: white !important;

}
.react-datepicker__day--selected:focus-visible {
outline: none;

}

.react-datepicker__day--in-selecting-range {
  background-color: #F5F5F5 !important;
  color: black !important;
}

.react-datepicker__day--keyboard-selected{
  background-color: #F5F5F5 !important;
  color: black !important;
}
/* .react-datepicker__day--selected:hover {
  background-color: #ec4253 !important;
  color: white !important;

}
.react-datepicker__day--in-range{
  background-color: #F5F5F5 !important;
  color: black !important;
}
.react-datepicker__day--in-range:hover {
  background-color: #ec4253 !important;
}
.react-datepicker__day--in-selecting-range {
  background-color: #F5F5F5 !important;
  color: black !important;
}

.react-datepicker__day--keyboard-selected ,.react-datepicker__day--selected{
  background-color:#ec4253 !important;
  color: white !important;

}
.react-datepicker__day--keyboard-selected ,.react-datepicker__day--in-range{
  background-color: #F5F5F5 !important;
  color: black !important;

} */
.react-datepicker__portal{
  height: 100%;
}